import { Injectable } from '@angular/core';
import { NavController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public authToken: string = '';
  private authTokenExp: number = 0;
  private userId: string = '';
  private userEmail: string = '';
  private timeOutToken: any;
  private impersonationUserId: string = '';
  private impersonationUserEmail: string = '';

  constructor(private navCtrl: NavController,
              private translate: TranslateService,
              private toastController: ToastController,
              private cookieSrv: CookieService) {
    this.authToken = '';
  }

  setAuthToken(token: string) {
    this.authToken = token;
  }

  getAuthToken() {
    if (new Date().getTime() > this.authTokenExp){
      this.translate.get('SessionEndedMessage').subscribe((message) => {
        this.presentToast(message);
      });
      this.logOut();
      return '';
    } else {
      return this.authToken;
    }
  }

  setAuthTokenExp(seconds: number) {
    const ns = seconds * 1000;
    this.authTokenExp = new Date().getTime() + ns;
    clearTimeout(this.timeOutToken);
    this.timeOutToken = setTimeout(() => {
      this.translate.get('SessionEndedMessage').subscribe((message) => {
        this.presentToast(message);
      });
      this.logOut();
    }, ns);
  }

  getAuthTokenExp() {
    return this.authTokenExp;
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  getUserId() {
    return this.userId;
  }

  setUserEmail(email: string) {
    this.userEmail = email;
  }

  getUserEmail(){
    return this.userEmail;
  }

  isAuthenticated() {
    return this.authToken !== '';
  }

  setImpersonationUserId(userId: string) {
    this.impersonationUserId = userId;
  }

  getImpersonationUserId() {
    return this.impersonationUserId;
  }

  setImpersonationUserEmail(email: string) {
    this.impersonationUserEmail = email;
  }

  getImpersonationUserEmail(){
    return this.impersonationUserEmail;
  }

  getActiveUserId(){
    if (this.impersonationUserId && this.impersonationUserId !== ''){
      return this.impersonationUserId;
    } else {
      return this.userId;
    }
  }

  isImpersonating() {
    return (this.impersonationUserId && this.impersonationUserId !== '');
  }

  logOut(){
    // clear all variables
    this.authToken = '';
    this.authTokenExp = 0;
    clearTimeout(this.timeOutToken);
    this.userId = '';
    this.userEmail = '';
    this.impersonationUserId = '';
    this.impersonationUserEmail = '';
    this.cookieSrv.deleteAll();
    localStorage.clear();
    this.navCtrl.navigateRoot('/login');
  }

  async presentToast(note: string) {
    const toast = await this.toastController.create({
      message: note,
      duration: 3000,
      position: 'middle'
    });
    toast.present();
  }

}
