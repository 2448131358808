<ion-app>
  <ion-menu contentId="main-content" side="start">
    <ion-header>
      <ion-toolbar>
        <ion-title color="light">
          <ion-text style="color: #f8fcfc" >Waiter </ion-text><ion-text style="color: #FF0000">Now</ion-text>
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <ion-list lines="none">
        <ion-menu-toggle>
          <ion-item routerLink="/main">
            <ion-icon name="pin" slot="start"></ion-icon>
            <ion-label> {{ 'Title-locations' | translate }} </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <!-- <ion-menu-toggle>
          <ion-item (click)="toBusinessApp()">
            <ion-icon name="log-in-outline" slot="start"></ion-icon>
            <ion-label> {{ 'Title-businessapp' | translate }} </ion-label>
          </ion-item>
        </ion-menu-toggle> -->
        <!-- <ion-menu-toggle>
          <ion-item routerLink="/account">
            <ion-icon name="settings-outline" slot="start"></ion-icon>
            <ion-label> {{ 'Settings' | translate }} </ion-label>
          </ion-item>
        </ion-menu-toggle> -->
        <ion-menu-toggle>
          <ion-item (click)="switchLanguage()">
            <ion-icon name="earth" slot="start"></ion-icon>
            <ion-label> {{ 'Title-language' | translate }} </ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item routerLink="/feedback">
            <ion-icon name="chatbox-ellipses-outline" slot="start"></ion-icon>
            <ion-label>{{ 'Feedback' | translate }}</ion-label>
          </ion-item>
        </ion-menu-toggle>
        <ion-menu-toggle>
          <ion-item routerLink="/about">
            <ion-icon name="information-circle-outline" slot="start"></ion-icon>
            <ion-label> {{ 'Title-about' | translate }} </ion-label>
          </ion-item>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>

    <ion-footer *ngIf="isUserLoggedIn()">
      <ion-menu-toggle>
        <ion-item class="logout" lines="none" (click)=logout()>
          <ion-icon name="log-out-outline" slot="start"></ion-icon>
          <ion-label> {{ 'Log-out' | translate }}</ion-label>
        </ion-item>
      </ion-menu-toggle>
    </ion-footer>
  </ion-menu>

  <div id="main-content" class="ion-page">
    <ion-router-outlet main></ion-router-outlet>
  </div>
</ion-app>
