import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './core/services/auth.service';
import { GlobalVariables } from './global-variables';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  private darkTheme = false;

  constructor(private platform: Platform,
              // private splashScreen: SplashScreen,
              // private statusBar: StatusBar,
              private translate: TranslateService,
              private authSrv: AuthService,
              public globalVar: GlobalVariables) {
                this.initializeApp();
              }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.styleDefault();
      // this.splashScreen.hide();
      const userLang = navigator.language;
      // console.log('Language: ', userLang);
      if ( userLang.includes('es')) { // set language
        this.translate.setDefaultLang('es'); // language
        this.translate.use('es');
      } else {
        this.translate.setDefaultLang('en'); // language
        this.translate.use('en');
      }
    });
    this.globalVar.dark = true;
    this.darkTheme = this.globalVar.dark;
    this.toggleMode();
  }

  switchLanguage() {
    if (this.translate.currentLang === 'es') {
      this.translate.use('en');
    } else {
      this.translate.use('es');
    }
  }

  toggleMode() {
    document.body.classList.toggle('dark', this.darkTheme);
    this.globalVar.dark = this.darkTheme;
    this.darkTheme = !this.darkTheme;
  }

  toBusinessApp() {
    let suffix = '.com';
    if (!environment.production) {
      suffix = '.dev'
    }
    window.open('https://business.waiternow'+suffix, '_self');
    // window.open('http://localhost:8100', '_self');  // for testing
  }

  isUserLoggedIn(){
    return this.authSrv.authToken;
  }

  logout(){
    this.authSrv.logOut();
  }

}
