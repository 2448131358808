import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'main',
    loadChildren: () => import('./modules/main/main.module').then( m => m.MainPageModule),
    canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'menu',
    loadChildren: () => import('./modules/menu/menu.module').then( m => m.MenuPageModule),
    canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'about',
    loadChildren: () => import('./modules/misc/about/about.module').then( m => m.AboutPageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./modules/misc/feedback/feedback.module').then( m => m.FeedbackPageModule),
    canLoad: [AuthGuard], canActivate: [AuthGuard]
  },
  {
    path: 'resetpassword',
    loadChildren: () => import('./modules/misc/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
